<template>
  <div class="deliverRemind">
    <div class="header">
      <van-dropdown-menu active-color="#B9921A">
        <van-dropdown-item v-model="filterParams.taskCode" clearable :options="statusList" @change="changeStatus">
        </van-dropdown-item>
        <van-dropdown-item
          ref="dropdownTime"
          :title="fromDeliverAssistant ? $t('请选择时间') : selectDate" >
          <van-calendar
            style="height: 394px"
            :poppable="false"
            :show-title="false"
            :first-day-of-week="1"
            allow-same-day
            :min-date="minDate"
            :show-confirm="false"
            @confirm="onConfirm">
          </van-calendar>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
    <div class="totalCount">{{ $t('共') }} {{ totalCount}} {{ $t('条数据') }}</div>
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <Item
          :dataInfo="item"
          @click.native="toDetail(item)"/>
      </template>
    </List>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import List from '@/components/baseList/list.vue'
import Item from './ListItem.vue'
import loading from '@/utils/loading'
import deliveryServices from '@/services/deliveryServices.js'
import { mapGetters } from 'vuex'
import DeliverItem from '@/modules/deliverManage/component/deliver-item.vue'
export default {
  components: {
    DeliverItem,
    List,
    Item
  },
  data() {
    return {
      minDate: dayjs().subtract(12, 'month').toDate(),
      totalCount: 0,
      selectDate: dayjs().format('YYYY-MM-DD'),
      filterParams: {
        taskCode: '',
        planTime: {
          start: dayjs().format('YYYY-MM-DD 00:00:00'),
          end: dayjs().format('YYYY-MM-DD 23:59:59')
        }
      },
      fromDeliverAssistant: false
    }
  },
  computed: {
    ...mapGetters(['dictHash', 'userInfo']),
    statusList() {
      let res = this.dictHash && this.dictHash[4013] || []
      const sortList = ['DELIVERY_FIRST_FOLLOW', 'DELIVERY_FOLLOW', 'BOOK_VISIT']
      res = res.sort((a,b) => {
        return sortList.indexOf(a.code) - sortList.indexOf(b.code)
      })
      const arr = res.map((item) => {
        return {
          text: item.name,
          value: item.code
        }
      })
      arr.unshift({ text : this.$t('全部'), value: '' })
      return arr
    }
  },
  beforeRouteEnter(to, from, next){
    next((vm) => {
      if (to.query.taskCode) {
        vm.fromDeliverAssistant = true
        vm.filterParams.taskCode = to.query.taskCode
        // vm.filterParams.planTime.start = dayjs().subtract(1, 'year').format('YYYY-MM-DD 00:00:00')
        // vm.filterParams.planTime.end = dayjs().add(6, 'month').format('YYYY-MM-DD 23:59:59')
        delete vm.filterParams.planTime
      }
    })
  },
  methods: {
    // 改变状态
    changeStatus(value) {
      this.$refs.list.onRefresh()
    },
    onConfirm(value) {
      this.$refs.dropdownTime.toggle(false)
      this.selectDate = dayjs(value).format('YYYY-MM-DD')
      this.fromDeliverAssistant = false
      this.filterParams.planTime.start = dayjs(value).format('YYYY-MM-DD 00:00:00')
      this.filterParams.planTime.end = dayjs(value).format('YYYY-MM-DD 23:59:59')
      this.$refs.list.onRefresh()
    },
    // 获取列表数据
    fetchApi(obj={}) {
      const params = {
        ...this.filterParams,
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
      }
      loading.showLoading()
      return deliveryServices.deliverRemindList(params).then(res => {
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        return res
      })
    },
    toDetail(data){
      if (data.taskCode === 'DELIVERY_COST_APPLY') {
        this.$router.push({
          path: '/remoteDeliveryFee',
          query: {
            id: data.orderId
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.deliverRemind{
  display: flex;
  flex-direction: column;
  .header{
    height: 48px;
    display: flex;
    background: #fff;
    padding-left: 8px;
    padding-right: 16px;
    flex-direction: column;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 10;
    /deep/ .van-dropdown-menu {
      height: 48px;
      display: flex;
      align-items: center;
      flex: 1;
      .van-dropdown-menu__bar{
        height: 48px;
        background: none;
        box-shadow: none;
        flex: 1;
        display: flex;
        justify-content: space-between;
        .van-dropdown-menu__item{
          justify-content: flex-start;
          &:nth-of-type(2){
            display: flex;
            justify-content: flex-end
          }
        }
        .van-dropdown-menu__title{
          font-size: 13px;
        }
        .van-dropdown-menu__title::after{
          border-color: transparent transparent @black @black
        } 
      }
    }
    /deep/.van-dropdown-item{
      .van-cell__title{
        flex: 2 !important;
      }
      .van-cell__value{
        flex: 1 !important;
      }
    }
  }
  .totalCount {
    color: @black;
    padding: 10px 0 0 10px;
    font-size: 12px;
  }
  .content{
    display: flex;
    padding: 0 16px;
  }
}
</style>